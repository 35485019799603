import * as React from 'react';
import { Table } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';

import { ListLink } from '@/components/listlink';
import { Pager } from '@/components/pager';
import { SearchReset } from '@/components/search-reset';
import { Sorter } from '@/components/sorter';
import { getList } from '@/helper/apicalls';
import { WorkOrderListResponse } from '@/helper/interfaces';
import { formatDate } from '@/helper/utility';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getList(baseURL, parameters, settings);
}

export function List() {
  const { data, currentPage, numberOfPages, orderBy, order } = useLoaderData() as WorkOrderListResponse;
  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <Sorter name='id' orderBy={orderBy} order={order}>
              Work Order &#35;
            </Sorter>
            <Sorter name='date' orderBy={orderBy} order={order} />
            <Sorter name='company' orderBy={orderBy} order={order} />
            <Sorter name='project' orderBy={orderBy} order={order} />
            <Sorter name='contact' orderBy={orderBy} order={order} />
            <Sorter name='mixer' orderBy={orderBy} order={order} />
            <th>Invoice #</th>
            <th>Job #</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => {
              const date = d.date ?? d.session?.startTime;
              return (
                <tr key={d.id}>
                  <ListLink to={'/workorders/' + d.id}>WO {d.id}</ListLink>
                  <td>{formatDate(date)}</td>
                  <ListLink to={'/companies/' + d.company?.id}>{d.company?.name}</ListLink>
                  <ListLink to={'/projects/' + d.project?.id}>{d.project?.name}</ListLink>
                  <ListLink to={'/contacts/' + d.contact?.id}>{d.contact?.name}</ListLink>
                  <ListLink to={'/users/' + d.session?.mixer.id}>{d.session?.mixer.name}</ListLink>
                  <td>
                    <a href={'/invoices/' + d.invoiceId}>{d.invoiceId ? 'Inv ' + d.invoiceId : ''}</a>
                  </td>
                  <td>
                    <a href={'/jobs/' + d.jobId}>{d.jobId ? 'Job ' + d.jobId : ''}</a>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
      <SearchReset />
      <Pager currentPage={currentPage} numberOfPages={numberOfPages} />
    </React.Fragment>
  );
}
