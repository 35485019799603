import * as React from 'react';
import { Form, Table } from 'react-bootstrap';
import { Check2 } from 'react-bootstrap-icons';
import { useLoaderData } from 'react-router-dom';

import { ListLink } from '@/components/listlink';
import { Pager } from '@/components/pager';
import { SearchReset } from '@/components/search-reset';
import { Sorter } from '@/components/sorter';
import { getList } from '@/helper/apicalls';
import { InvoiceListResponse } from '@/helper/interfaces';
import { useStore } from '@/store';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getList(baseURL, parameters, settings);
}

export function List() {
  const { data, currentPage, numberOfPages, orderBy, order } = useLoaderData() as InvoiceListResponse;

  const { selectedInvoices, setSelectedInvoices } = useStore();
  const allInvoices = data?.map((invoice) => invoice.id);
  function handleSelect(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    const temporarySelected = event.target.checked
      ? [...selectedInvoices, id]
      : selectedInvoices.filter((s: number) => s !== id);
    setSelectedInvoices(temporarySelected);
  }
  function selectAll(event: React.ChangeEvent<HTMLInputElement>) {
    const temporarySelected = event.target.checked
      ? [...selectedInvoices, ...allInvoices]
      : selectedInvoices.filter((s: number) => !allInvoices.includes(s));
    setSelectedInvoices(temporarySelected);
  }

  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <th>
              <Form.Check
                id='selectAll'
                checked={
                  allInvoices?.length > 0
                    ? allInvoices.every((invoice: number) => selectedInvoices.includes(invoice))
                    : false
                }
                onChange={(event) => selectAll(event)}
              />
            </th>
            <Sorter name='id' orderBy={orderBy} order={order}>
              Invoice &#35;
            </Sorter>
            <th>Job #</th>
            <th>Company</th>
            <th>Contact</th>
            <th>Project</th>
            <th>Exported</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => (
              <tr key={d.id}>
                <td>
                  <Form.Check
                    id={'INV' + d.id}
                    checked={selectedInvoices?.includes(d.id)}
                    onChange={(event) => handleSelect(event, d.id)}
                  />
                </td>
                <ListLink to={`${baseURL}/${d.id}`}>Inv {d.id}</ListLink>
                <ListLink to={'/jobs/' + d.jobId}>Job {d.jobId}</ListLink>
                <td>{d.jobInfo.company.name}</td>
                <td>{d.jobInfo.contact.name}</td>
                <td>{d.jobInfo.project.name}</td>
                <td className='text-center'>{d.exported ? <Check2 aria-label='yes' /> : ''}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
      <SearchReset />
      <Pager currentPage={currentPage} numberOfPages={numberOfPages} />
    </React.Fragment>
  );
}
