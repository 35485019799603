import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { Button, Table } from 'react-bootstrap';
import { Journals, PencilSquare, Trash3 } from 'react-bootstrap-icons';
import { useLoaderData, useOutletContext } from 'react-router-dom';

import { CreateWOLink } from '@/components/createworkorderlink';
import { ListLink } from '@/components/listlink';
import { Sorter } from '@/components/sorter';
import { getDateList } from '@/helper/apicalls';
import { Session, SessionListResponse } from '@/helper/interfaces';
import { formatStatus, getColorClass } from '@/helper/utility';
import { useStore } from '@/store';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getDateList(baseURL, parameters, settings);
}

interface Modals {
  editSession: ModalFunction;
  deleteSession: ModalFunction;
  showNotes: ModalFunction;
}
interface ModalFunction {
  (session: Session): void;
}

export function List() {
  const { user } = useStore();

  const { data, orderBy, order } = useLoaderData() as SessionListResponse;
  const { editSession, deleteSession, showNotes } = useOutletContext<Modals>();

  const [disableModals, setDisableModals] = React.useState(false);

  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <Sorter name='company' orderBy={orderBy} order={order} />
            <Sorter name='project' orderBy={orderBy} order={order} />
            <Sorter name='contact' orderBy={orderBy} order={order} />
            {/* <Sorter name='date' orderBy={orderBy} order={order} /> */}
            <Sorter name='startTime' orderBy={orderBy} order={order} />
            <Sorter name='endTime' orderBy={orderBy} order={order} />
            <Sorter name='mixer' orderBy={orderBy} order={order} />
            <Sorter name='assistant' orderBy={orderBy} order={order} />
            <Sorter name='room' orderBy={orderBy} order={order} />
            <Sorter name='status' orderBy={orderBy} order={order} />
            <td></td>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => (
              <tr key={d.id} className={getColorClass(formatStatus(d.status, d?.workOrder?.isFinished))}>
                <ListLink to={`/companies/${d.company.id}`}>{d.company.name}</ListLink>
                <ListLink to={`/projects/${d.project.id}`}>
                  <b>{d.project.name}</b>
                </ListLink>
                <ListLink to={`/contacts/${d.contact.id}`}>
                  {d.contact.firstName} {d.contact.lastName}
                </ListLink>
                {/* <td>{format(parseISO(d.startTime), 'ccc MMMM d, yyyy')}</td> */}
                <td>{format(parseISO(d.startTime), 'h:mm a')}</td>
                <td>{format(parseISO(d.endTime), 'h:mm a')}</td>
                <td>
                  {d.mixer.firstName} {d.mixer.lastName}
                </td>
                <td>
                  {d?.assistant?.firstName} {d?.assistant?.lastName}
                </td>
                <td>
                  <b>{d.room.name}</b>
                </td>
                <td>{formatStatus(d.status, d?.workOrder?.isFinished)}</td>
                <td className='text-center'>
                  <div>
                    <Button
                      variant='link'
                      className={'btn-icon' + (d.notes ? ' highlight' : '')}
                      onClick={() => showNotes(d)}
                      disabled={disableModals}
                    >
                      <Journals aria-label='show notes' />
                    </Button>
                    <CreateWOLink sessionId={d.id} disableModals={disableModals} setDisableModals={setDisableModals} />
                    {user?.role?.editSessions && (
                      <React.Fragment>
                        <Button
                          variant='link'
                          className='btn-icon'
                          onClick={() => editSession(d)}
                          disabled={disableModals}
                        >
                          <PencilSquare aria-label='edit' />
                        </Button>
                        <Button
                          variant='link'
                          className='btn-icon'
                          onClick={() => deleteSession(d)}
                          disabled={disableModals}
                        >
                          <Trash3 aria-label='delete' />
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}
