import { format } from 'date-fns';

import { downloadCSV } from '@/helper/apicalls';

import { baseURL } from './const';

export async function exportAction({ request }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body.invoiceIds) body.invoiceIds = JSON.parse(body.invoiceIds);

  // eslint-disable-next-line unicorn/prefer-ternary
  if (body.invoiceIds?.length > 0 && request.method == 'PATCH') {
    return downloadCSV(baseURL, body, 'LIME_Invoices_' + format(new Date(), 'yyyy_MM_dd_HH.mm.ss'));
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
