import { downloadPDF } from '@/helper/apicalls';

import { baseURL } from './const';

export async function printLoader({ params }: any) {
  // eslint-disable-next-line unicorn/prefer-ternary
  if (params.id) {
    return downloadPDF(baseURL, params.id, 'LIME_Invoice_' + params.id);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
