import { format, parseISO, startOfDay } from 'date-fns';

import { downloadCSV } from '@/helper/apicalls';

export async function foodAction({ request }: any) {
  const body = Object.fromEntries(await request.formData());

  body.date = startOfDay(parseISO(body.date)).toISOString();

  // eslint-disable-next-line unicorn/prefer-ternary
  if (body.date && request.method == 'POST') {
    return downloadCSV('/reports/food', body, 'LIME_FoodReport_' + format(parseISO(body.date), 'yyyy_MM_dd'));
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
