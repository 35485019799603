export const allBackups = [
  { key: 'UNIFIED_12', value: 'Unified 12' },
  { key: 'UNIFIED_13', value: 'Unified 13', default: true }
];

export const allStatuses = [
  { key: 'AWAY', value: 'Away' },
  { key: 'CALL', value: 'Call' },
  { key: 'CHALLENGE', value: 'Challenge' },
  { key: 'FIRM', value: 'Firm' },
  { key: 'FLEX', value: 'Flex' },
  { key: 'HOLD1', value: '1st Hold' },
  { key: 'HOLD2', value: '2nd Hold' },
  { key: 'OUT', value: 'Out' },
  { key: 'SELL', value: 'Sell' },
  { key: 'TOUR', value: 'Tour' },
  { key: 'FINISHED', value: 'Finished' }
];

export const allStates = [
  { key: 'AL', value: 'Alabama' },
  { key: 'AK', value: 'Alaska' },
  { key: 'AZ', value: 'Arizona' },
  { key: 'AR', value: 'Arkansas' },
  { key: 'CA', value: 'California' },
  { key: 'CO', value: 'Colorado' },
  { key: 'CT', value: 'Connecticut' },
  { key: 'DE', value: 'Delaware' },
  { key: 'DC', value: 'District Of Columbia' },
  { key: 'FL', value: 'Florida' },
  { key: 'GA', value: 'Georgia' },
  { key: 'HI', value: 'Hawaii' },
  { key: 'ID', value: 'Idaho' },
  { key: 'IL', value: 'Illinois' },
  { key: 'IN', value: 'Indiana' },
  { key: 'IA', value: 'Iowa' },
  { key: 'KS', value: 'Kansas' },
  { key: 'KY', value: 'Kentucky' },
  { key: 'LA', value: 'Louisiana' },
  { key: 'ME', value: 'Maine' },
  { key: 'MD', value: 'Maryland' },
  { key: 'MA', value: 'Massachusetts' },
  { key: 'MI', value: 'Michigan' },
  { key: 'MN', value: 'Minnesota' },
  { key: 'MS', value: 'Mississippi' },
  { key: 'MO', value: 'Missouri' },
  { key: 'MT', value: 'Montana' },
  { key: 'NE', value: 'Nebraska' },
  { key: 'NV', value: 'Nevada' },
  { key: 'NH', value: 'New Hampshire' },
  { key: 'NJ', value: 'New Jersey' },
  { key: 'NM', value: 'New Mexico' },
  { key: 'NY', value: 'New York' },
  { key: 'NC', value: 'North Carolina' },
  { key: 'ND', value: 'North Dakota' },
  { key: 'OH', value: 'Ohio' },
  { key: 'OK', value: 'Oklahoma' },
  { key: 'OR', value: 'Oregon' },
  { key: 'PA', value: 'Pennsylvania' },
  { key: 'RI', value: 'Rhode Island' },
  { key: 'SC', value: 'South Carolina' },
  { key: 'SD', value: 'South Dakota' },
  { key: 'TN', value: 'Tennessee' },
  { key: 'TX', value: 'Texas' },
  { key: 'UT', value: 'Utah' },
  { key: 'VT', value: 'Vermont' },
  { key: 'VA', value: 'Virginia' },
  { key: 'WA', value: 'Washington' },
  { key: 'WV', value: 'West Virginia' },
  { key: 'WI', value: 'Wisconsin' },
  { key: 'WY', value: 'Wyoming' }
];

export const allCountries = [
  { key: 'AFG', value: 'Afghanistan' },
  { key: 'ALB', value: 'Albania' },
  { key: 'DZA', value: 'Algeria' },
  { key: 'ASM', value: 'American Samoa' },
  { key: 'AND', value: 'Andorra' },
  { key: 'AGO', value: 'Angola' },
  { key: 'AIA', value: 'Anguilla' },
  { key: 'ATA', value: 'Antarctica' },
  { key: 'ATG', value: 'Antigua and Barbuda' },
  { key: 'ARG', value: 'Argentina' },
  { key: 'ARM', value: 'Armenia' },
  { key: 'ABW', value: 'Aruba' },
  { key: 'AUS', value: 'Australia' },
  { key: 'AUT', value: 'Austria' },
  { key: 'AZE', value: 'Azerbaijan' },
  { key: 'BHS', value: 'Bahamas' },
  { key: 'BHR', value: 'Bahrain' },
  { key: 'BGD', value: 'Bangladesh' },
  { key: 'BRB', value: 'Barbados' },
  { key: 'BLR', value: 'Belarus' },
  { key: 'BEL', value: 'Belgium' },
  { key: 'BLZ', value: 'Belize' },
  { key: 'BEN', value: 'Benin' },
  { key: 'BMU', value: 'Bermuda' },
  { key: 'BTN', value: 'Bhutan' },
  { key: 'BOL', value: 'Bolivia' },
  { key: 'BES', value: 'Bonaire, Sint Eustatius and Saba' },
  { key: 'BIH', value: 'Bosnia and Herzegovina' },
  { key: 'BWA', value: 'Botswana' },
  { key: 'BVT', value: 'Bouvet Island' },
  { key: 'BRA', value: 'Brazil' },
  { key: 'IOT', value: 'British Indian Ocean Territory' },
  { key: 'BRN', value: 'Brunei Darussalam' },
  { key: 'BGR', value: 'Bulgaria' },
  { key: 'BFA', value: 'Burkina Faso' },
  { key: 'BDI', value: 'Burundi' },
  { key: 'CPV', value: 'Cabo Verde' },
  { key: 'KHM', value: 'Cambodia' },
  { key: 'CMR', value: 'Cameroon' },
  { key: 'CAN', value: 'Canada' },
  { key: 'CYM', value: 'Cayman Islands' },
  { key: 'CAF', value: 'Central African Republic' },
  { key: 'TCD', value: 'Chad' },
  { key: 'CHL', value: 'Chile' },
  { key: 'CHN', value: 'China' },
  { key: 'CXR', value: 'Christmas Island' },
  { key: 'CCK', value: 'Cocos (Keeling) Islands' },
  { key: 'COL', value: 'Colombia' },
  { key: 'COM', value: 'Comoros' },
  { key: 'COD', value: 'Democratic Republic of the Congo' },
  { key: 'COG', value: 'Congo' },
  { key: 'COK', value: 'Cook Islands' },
  { key: 'CRI', value: 'Costa Rica' },
  { key: 'HRV', value: 'Croatia' },
  { key: 'CUB', value: 'Cuba' },
  { key: 'CUW', value: 'Curaçao' },
  { key: 'CYP', value: 'Cyprus' },
  { key: 'CZE', value: 'Czechia' },
  { key: 'CIV', value: 'Côte d&apos;Ivoire' },
  { key: 'DNK', value: 'Denmark' },
  { key: 'DJI', value: 'Djibouti' },
  { key: 'DMA', value: 'Dominica' },
  { key: 'DOM', value: 'Dominican Republic' },
  { key: 'ECU', value: 'Ecuador' },
  { key: 'EGY', value: 'Egypt' },
  { key: 'SLV', value: 'El Salvador' },
  { key: 'GNQ', value: 'Equatorial Guinea' },
  { key: 'ERI', value: 'Eritrea' },
  { key: 'EST', value: 'Estonia' },
  { key: 'SWZ', value: 'Eswatini' },
  { key: 'ETH', value: 'Ethiopia' },
  { key: 'FLK', value: 'Falkland Islands' },
  { key: 'FRO', value: 'Faroe Islands' },
  { key: 'FJI', value: 'Fiji' },
  { key: 'FIN', value: 'Finland' },
  { key: 'FRA', value: 'France' },
  { key: 'GUF', value: 'French Guiana' },
  { key: 'PYF', value: 'French Polynesia' },
  { key: 'ATF', value: 'French Southern Territories' },
  { key: 'GAB', value: 'Gabon' },
  { key: 'GMB', value: 'Gambia' },
  { key: 'GEO', value: 'Georgia' },
  { key: 'DEU', value: 'Germany' },
  { key: 'GHA', value: 'Ghana' },
  { key: 'GIB', value: 'Gibraltar' },
  { key: 'GRC', value: 'Greece' },
  { key: 'GRL', value: 'Greenland' },
  { key: 'GRD', value: 'Grenada' },
  { key: 'GLP', value: 'Guadeloupe' },
  { key: 'GUM', value: 'Guam' },
  { key: 'GTM', value: 'Guatemala' },
  { key: 'GGY', value: 'Guernsey' },
  { key: 'GIN', value: 'Guinea' },
  { key: 'GNB', value: 'Guinea-Bissau' },
  { key: 'GUY', value: 'Guyana' },
  { key: 'HTI', value: 'Haiti' },
  { key: 'HMD', value: 'Heard Island and McDonald Islands' },
  { key: 'VAT', value: 'Holy See' },
  { key: 'HND', value: 'Honduras' },
  { key: 'HKG', value: 'Hong Kong' },
  { key: 'HUN', value: 'Hungary' },
  { key: 'ISL', value: 'Iceland' },
  { key: 'IND', value: 'India' },
  { key: 'IDN', value: 'Indonesia' },
  { key: 'IRN', value: 'Islamic Republic of Iran' },
  { key: 'IRQ', value: 'Iraq' },
  { key: 'IRL', value: 'Ireland' },
  { key: 'IMN', value: 'Isle of Man' },
  { key: 'ISR', value: 'Israel' },
  { key: 'ITA', value: 'Italy' },
  { key: 'JAM', value: 'Jamaica' },
  { key: 'JPN', value: 'Japan' },
  { key: 'JEY', value: 'Jersey' },
  { key: 'JOR', value: 'Jordan' },
  { key: 'KAZ', value: 'Kazakhstan' },
  { key: 'KEN', value: 'Kenya' },
  { key: 'KIR', value: 'Kiribati' },
  { key: 'PRK', value: 'Democratic People&apos;s Republic of Korea' },
  { key: 'KOR', value: 'Republic of Korea' },
  { key: 'KWT', value: 'Kuwait' },
  { key: 'KGZ', value: 'Kyrgyzstan' },
  { key: 'LAO', value: 'Lao People&apos;s Democratic Republic' },
  { key: 'LVA', value: 'Latvia' },
  { key: 'LBN', value: 'Lebanon' },
  { key: 'LSO', value: 'Lesotho' },
  { key: 'LBR', value: 'Liberia' },
  { key: 'LBY', value: 'Libya' },
  { key: 'LIE', value: 'Liechtenstein' },
  { key: 'LTU', value: 'Lithuania' },
  { key: 'LUX', value: 'Luxembourg' },
  { key: 'MAC', value: 'Macao' },
  { key: 'MDG', value: 'Madagascar' },
  { key: 'MWI', value: 'Malawi' },
  { key: 'MYS', value: 'Malaysia' },
  { key: 'MDV', value: 'Maldives' },
  { key: 'MLI', value: 'Mali' },
  { key: 'MLT', value: 'Malta' },
  { key: 'MHL', value: 'Marshall Islands' },
  { key: 'MTQ', value: 'Martinique' },
  { key: 'MRT', value: 'Mauritania' },
  { key: 'MUS', value: 'Mauritius' },
  { key: 'MYT', value: 'Mayotte' },
  { key: 'MEX', value: 'Mexico' },
  { key: 'FSM', value: 'Federated States of Micronesia' },
  { key: 'MDA', value: 'Republic of Moldova' },
  { key: 'MCO', value: 'Monaco' },
  { key: 'MNG', value: 'Mongolia' },
  { key: 'MNE', value: 'Montenegro' },
  { key: 'MSR', value: 'Montserrat' },
  { key: 'MAR', value: 'Morocco' },
  { key: 'MOZ', value: 'Mozambique' },
  { key: 'MMR', value: 'Myanmar' },
  { key: 'NAM', value: 'Namibia' },
  { key: 'NRU', value: 'Nauru' },
  { key: 'NPL', value: 'Nepal' },
  { key: 'NLD', value: 'Netherlands' },
  { key: 'NCL', value: 'New Caledonia' },
  { key: 'NZL', value: 'New Zealand' },
  { key: 'NIC', value: 'Nicaragua' },
  { key: 'NER', value: 'Niger' },
  { key: 'NGA', value: 'Nigeria' },
  { key: 'NIU', value: 'Niue' },
  { key: 'NFK', value: 'Norfolk Island' },
  { key: 'MNP', value: 'Northern Mariana Islands' },
  { key: 'NOR', value: 'Norway' },
  { key: 'OMN', value: 'Oman' },
  { key: 'PAK', value: 'Pakistan' },
  { key: 'PLW', value: 'Palau' },
  { key: 'PSE', value: 'Palestine, State of' },
  { key: 'PAN', value: 'Panama' },
  { key: 'PNG', value: 'Papua New Guinea' },
  { key: 'PRY', value: 'Paraguay' },
  { key: 'PER', value: 'Peru' },
  { key: 'PHL', value: 'Philippines' },
  { key: 'PCN', value: 'Pitcairn' },
  { key: 'POL', value: 'Poland' },
  { key: 'PRT', value: 'Portugal' },
  { key: 'PRI', value: 'Puerto Rico' },
  { key: 'QAT', value: 'Qatar' },
  { key: 'MKD', value: 'Republic of North Macedonia' },
  { key: 'ROU', value: 'Romania' },
  { key: 'RUS', value: 'Russian Federation' },
  { key: 'RWA', value: 'Rwanda' },
  { key: 'REU', value: 'Réunion' },
  { key: 'BLM', value: 'Saint Barthélemy' },
  { key: 'SHN', value: 'Saint Helena, Ascension and Tristan da Cunha' },
  { key: 'KNA', value: 'Saint Kitts and Nevis' },
  { key: 'LCA', value: 'Saint Lucia' },
  { key: 'MAF', value: 'Saint Martin (French)' },
  { key: 'SPM', value: 'Saint Pierre and Miquelon' },
  { key: 'VCT', value: 'Saint Vincent and the Grenadines' },
  { key: 'WSM', value: 'Samoa' },
  { key: 'SMR', value: 'San Marino' },
  { key: 'STP', value: 'Sao Tome and Principe' },
  { key: 'SAU', value: 'Saudi Arabia' },
  { key: 'SEN', value: 'Senegal' },
  { key: 'SRB', value: 'Serbia' },
  { key: 'SYC', value: 'Seychelles' },
  { key: 'SLE', value: 'Sierra Leone' },
  { key: 'SGP', value: 'Singapore' },
  { key: 'SXM', value: 'Sint Maarten (Dutch)' },
  { key: 'SVK', value: 'Slovakia' },
  { key: 'SVN', value: 'Slovenia' },
  { key: 'SLB', value: 'Solomon Islands' },
  { key: 'SOM', value: 'Somalia' },
  { key: 'ZAF', value: 'South Africa' },
  { key: 'SGS', value: 'South Georgia and the South Sandwich Islands' },
  { key: 'SSD', value: 'South Sudan' },
  { key: 'ESP', value: 'Spain' },
  { key: 'LKA', value: 'Sri Lanka' },
  { key: 'SDN', value: 'Sudan' },
  { key: 'SUR', value: 'Suriname' },
  { key: 'SJM', value: 'Svalbard and Jan Mayen' },
  { key: 'SWE', value: 'Sweden' },
  { key: 'CHE', value: 'Switzerland' },
  { key: 'SYR', value: 'Syrian Arab Republic' },
  { key: 'TWN', value: 'Taiwan' },
  { key: 'TJK', value: 'Tajikistan' },
  { key: 'TZA', value: 'United Republic of Tanzania' },
  { key: 'THA', value: 'Thailand' },
  { key: 'TLS', value: 'Timor-Leste' },
  { key: 'TGO', value: 'Togo' },
  { key: 'TKL', value: 'Tokelau' },
  { key: 'TON', value: 'Tonga' },
  { key: 'TTO', value: 'Trinidad and Tobago' },
  { key: 'TUN', value: 'Tunisia' },
  { key: 'TUR', value: 'Turkey' },
  { key: 'TKM', value: 'Turkmenistan' },
  { key: 'TCA', value: 'Turks and Caicos Islands' },
  { key: 'TUV', value: 'Tuvalu' },
  { key: 'UGA', value: 'Uganda' },
  { key: 'UKR', value: 'Ukraine' },
  { key: 'ARE', value: 'United Arab Emirates' },
  { key: 'GBR', value: 'United Kingdom of Great Britain and Northern Ireland' },
  { key: 'UMI', value: 'United States Minor Outlying Islands' },
  { key: 'USA', value: 'United States of America' },
  { key: 'URY', value: 'Uruguay' },
  { key: 'UZB', value: 'Uzbekistan' },
  { key: 'VUT', value: 'Vanuatu' },
  { key: 'VEN', value: 'Venezuela' },
  { key: 'VNM', value: 'Viet Nam' },
  { key: 'VGB', value: 'Virgin Islands (British)' },
  { key: 'VIR', value: 'Virgin Islands (U.S.)' },
  { key: 'WLF', value: 'Wallis and Futuna' },
  { key: 'ESH', value: 'Western Sahara' },
  { key: 'YEM', value: 'Yemen' },
  { key: 'ZMB', value: 'Zambia' },
  { key: 'ZWE', value: 'Zimbabwe' },
  { key: 'ALA', value: 'Åland Islands' }
];
