import * as React from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useLoaderData, useLocation } from 'react-router-dom';

import { Expenses } from '@/components/expenses';
import { FormButtons } from '@/components/formbuttons';
import { InfoTable } from '@/components/infotable';
import { Input } from '@/components/input';
import { InputTextArea } from '@/components/input-textarea';
import { ListLink } from '@/components/listlink';
import { ModalForm } from '@/components/modal-form';
import { Patcher } from '@/components/patcher';
import { PatcherSwitch } from '@/components/patcher-switch';
import { SelectorAPIGroup } from '@/components/selector-api-group';
import { SelectorAPIHelper } from '@/components/selector-api-helper';
import { SelectorDate } from '@/components/selector-date';
import { Services } from '@/components/services';
import { Stock } from '@/components/stock';
import { deleteSingle, getSingle, patchSingle, postSingle, putSingle } from '@/helper/apicalls';
import { Estimate } from '@/helper/interfaces';
import { formatDate, formatMoney } from '@/helper/utility';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.companyId) body.companyId = JSON.parse(body.companyId);
  if (body.contactId) body.contactId = JSON.parse(body.contactId);
  if (body.projectId) body.projectId = JSON.parse(body.projectId);

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'PATCH') {
    return patchSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const data = useLoaderData() as Estimate;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const disabled = data?.deleted || data?.job?.deleted || data?.invoiceId ? true : false;

  const modal = React.useRef(null);
  const groupHelper = React.useRef(null);
  const [elementSelect, setElementSelect] = React.useState<Partial<Estimate>>();

  function editEstimate() {
    setElementSelect({});
    modal.current.openModal();
  }

  function saveForm() {
    setElementSelect({
      companyId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="companyId"]').value, 10),
      contactId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="contactId"]').value, 10),
      projectId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="projectId"]').value, 10),
      date: document.querySelector<HTMLInputElement>('input[name="date"]').value,
      poNumber: document.querySelector<HTMLInputElement>('input[name="poNumber"]').value,
      jobNumber: document.querySelector<HTMLInputElement>('input[name="jobNumber"]').value,
      message: document.querySelector<HTMLTextAreaElement>('textarea[name="message"]').value
    });
  }

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This estimate was deleted and can no longer be edited.
        </Alert>
      )}
      {data?.job?.deleted && (
        <Alert variant='danger' className='mt-3'>
          The parent&nbsp;
          <ListLink to={'/jobs/' + data.job.id} nowrapper>
            Job {data.job.id}
          </ListLink>
          &nbsp;was deleted so this work order can no longer be edited.
        </Alert>
      )}
      {data.invoiceId && (
        <Alert variant='warning' className='mt-3'>
          This estimate was added to invoice #{data.invoiceId} and can no longer be edited.
        </Alert>
      )}
      {data.isApproved && !data.invoiceId && (
        <Alert variant='warning' className='mt-3'>
          Estimates that have been approved can no longer be edited.
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            Estimate #{data.id}
            <Button type='button' size='sm' className='float-end' onClick={editEstimate} disabled={disabled}>
              <PencilSquare className='icon-align' /> Edit
            </Button>
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Company', content: data.company.name, link: '/companies/' + data.company.id },
              { title: 'Contact', content: data.contact.name, link: '/contacts/' + data.contact.id },
              { title: 'Project', content: data.project.name, link: '/projects/' + data.project.id },
              { title: 'Date', content: formatDate(data.date) },
              {
                title: 'Notes',
                content: data.company.notes + '\n' + data.contact.notes + '\n' + data.project.notes
              },
              { title: 'P.O. #', content: data.poNumber },
              { title: 'Client Job #', content: data.jobNumber },
              { title: 'Message', content: data.message }
            ]}
          />
          <hr />
          <Patcher
            id={data.id}
            values={{ isApproved: data.isApproved.toString() }}
            url='/estimates'
            disabled={disabled}
          >
            <PatcherSwitch name='isApproved' displayName='Approved' />
          </Patcher>
        </Card.Body>
      </Card>

      <Services
        baseURL={baseURL}
        id={data.id}
        services={data.services}
        total={data.servicesTotal}
        disabled={disabled || data.isApproved}
        selectMixer={false}
      />

      <Stock
        baseURL={baseURL}
        id={data.id}
        stock={data.stock}
        total={data.stockTotal}
        disabled={disabled || data.isApproved}
      />

      <Expenses
        baseURL={baseURL}
        id={data.id}
        expenses={data.expenses}
        total={data.expensesTotal}
        disabled={disabled || data.isApproved}
      />

      <p className='text-end mt-3'>
        <b>Estimate Total:</b> {formatMoney(data.estimateTotal)}
      </p>

      <FormButtons
        type='Estimate'
        redirectTo={redirectTo}
        showDelete={!disabled && !data.isApproved}
        showPrint={!data.deleted}
      >
        <React.Fragment>Are you sure you want to delete this estimate?</React.Fragment>
      </FormButtons>

      <ModalForm title='Edit Estimate' url={baseURL} ref={modal} putId={data.id} draggable>
        <SelectorDate value={data.date} valueSelect={elementSelect?.date} />
        <SelectorAPIGroup
          value={{ companyId: data.company.id, contactId: data.contact.id, projectId: data.project.id }}
          valueSelect={{
            companyId: elementSelect?.companyId,
            contactId: elementSelect?.contactId,
            projectId: elementSelect?.projectId
          }}
          addCompany={() => groupHelper.current.addCompany()}
          addContact={() => groupHelper.current.addContact()}
          linkContact={() => groupHelper.current.linkContact()}
          addProject={() => groupHelper.current.addProject()}
        />
        <Input name='poNumber' displayName='P.O. #' value={data.poNumber} valueSelect={elementSelect?.poNumber} />
        <Input name='jobNumber' value={data.jobNumber} valueSelect={elementSelect?.jobNumber} />
        <InputTextArea name='message' size='col-12' value={data?.message || ''} valueSelect={elementSelect?.message} />
      </ModalForm>

      <SelectorAPIHelper
        elementSelect={elementSelect}
        setElementSelect={setElementSelect}
        saveForm={saveForm}
        modal={modal}
        ref={groupHelper}
      />
    </React.Fragment>
  );
}
