import * as React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Form as RouterForm, useFetcher, useNavigate } from 'react-router-dom';

import { ModalWarning } from '@/components/modal-warning';

interface Properties {
  type: string;
  redirectTo: string;
  disabled?: boolean;
  showDelete?: boolean;
  showPrint?: boolean;
  children: React.ReactNode;
}

export function FormButtons({ type, redirectTo, showDelete = true, showPrint = false, children }: Properties) {
  const [modalDelete, setModalDelete] = React.useState(false);
  const handleCloseModal = () => setModalDelete(false);
  const handleOpenModal = () => setModalDelete(true);

  const navigate = useNavigate();
  function handleBack(event: React.FormEvent) {
    event.preventDefault();
    navigate(redirectTo);
  }

  const fetcher = useFetcher();
  const [isLoading, setIsLoading] = React.useState(false);
  const modalError = React.useRef(null);
  const [message, setMessage] = React.useState('');
  React.useEffect(() => {
    if (fetcher.state == 'loading' || fetcher.state == 'submitting') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (fetcher.data?.status == 400) {
        setMessage(fetcher.data?.message);
        modalError.current.openModal();
      }
    }
  }, [fetcher]);

  function handlePrint() {
    fetcher.load('print');
  }

  return (
    <Row className='mt-3 mb-3'>
      <Col className='col-12 text-end'>
        {showDelete && (
          <Button variant='danger' size='sm' className='me-2' type='button' onClick={handleOpenModal}>
            Delete
          </Button>
        )}
        {showPrint && (
          <Button variant='primary' size='sm' className='me-2' type='button' onClick={handlePrint}>
            Print
          </Button>
        )}
        <Button variant='secondary' size='sm' type='button' onClick={handleBack}>
          Go Back
        </Button>
      </Col>
      <Modal show={modalDelete} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {type}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Form as={RouterForm} noValidate method='delete'>
            <Form.Control type='hidden' name='redirectTo' value={redirectTo} />
            <Button variant='secondary' type='button' className='me-2' onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant='danger' type='submit'>
              Delete
            </Button>
          </Form>
        </Modal.Footer>
      </Modal>
      <ModalWarning title='Error' ref={modalError}>
        {message}
      </ModalWarning>
      <Modal show={isLoading} backdrop='static' keyboard={false}>
        <Modal.Body className='text-center'>
          The PDF file is exporting...
          <br />
          <br />
          <Spinner />
          <br />
        </Modal.Body>
      </Modal>
    </Row>
  );
}
