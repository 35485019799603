/* eslint-disable sonarjs/no-duplicate-string */
import * as React from 'react';
import { Alert, Button, Card, Col, Dropdown, DropdownButton, Form, Table } from 'react-bootstrap';
import { Check2, PencilSquare, Trash3 } from 'react-bootstrap-icons';
import { useLoaderData, useLocation } from 'react-router-dom';

import { FormButtons } from '@/components/formbuttons';
import { InfoTable } from '@/components/infotable';
import { Input } from '@/components/input';
import { InputNumber } from '@/components/input-number';
import { InputTextArea } from '@/components/input-textarea';
import { ListLink } from '@/components/listlink';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { ModalWarning } from '@/components/modal-warning';
import { SelectorAPIGroup } from '@/components/selector-api-group';
import { SelectorAPIHelper } from '@/components/selector-api-helper';
import { SelectorTable } from '@/components/selector-table';
import { deleteSingle, getSingle, postSingle, putSingle } from '@/helper/apicalls';
import { Job } from '@/helper/interfaces';
import { formatDate, formatMoney } from '@/helper/utility';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.companyId) body.companyId = JSON.parse(body.companyId);
  if (body.contactId) body.contactId = JSON.parse(body.contactId);
  if (body.projectId) body.projectId = JSON.parse(body.projectId);

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const data = useLoaderData() as Job;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const modalEdit = React.useRef(null);
  const groupHelper = React.useRef(null);
  const [elementSelect, setElementSelect] = React.useState<Partial<Job>>();
  function editJob() {
    setElementSelect({});
    modalEdit.current.openModal();
  }

  function saveForm() {
    setElementSelect({
      companyId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="companyId"]').value, 10),
      contactId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="contactId"]').value, 10),
      projectId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="projectId"]').value, 10),
      notes: document.querySelector<HTMLTextAreaElement>('textarea[name="notes"]').value
    });
  }

  const modalAddWorkOrder = React.useRef(null);
  const modalDeleteWorkOrder = React.useRef(null);
  const modalAddEstimate = React.useRef(null);
  const modalDeleteEstimate = React.useRef(null);
  const modalInvoice = React.useRef(null);
  const modalWarning = React.useRef(null);

  const [deleteId, setDeleteId] = React.useState<number>();
  const modalDeleteInvoice = React.useRef(null);
  const [isEstimate, setIsEstimate] = React.useState<boolean>();
  const [estimateTotal, setEstimateTotal] = React.useState<number>();
  const [workOrderTotal, setWorkOrderTotal] = React.useState<number>();
  const [invoiceTotal, setInvoiceTotal] = React.useState<number>();
  const [isNegative, setIsNegative] = React.useState<boolean>(false);

  const [selectedEstimates, setSelectedEstimates] = React.useState<number[]>([]);
  function handleSelectEstimate(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    const temporarySelected = event.target.checked
      ? [...selectedEstimates, id]
      : selectedEstimates.filter((s) => s !== id);
    setSelectedEstimates(temporarySelected);
  }
  function handleEstimateInvoiceSubmit() {
    if (selectedEstimates.length === 0) {
      modalWarning.current.openModal(
        'Please select at least one estimate to create an invoice.',
        'Cannot Create Invoice'
      );
    } else {
      const getEstimates = data.estimates.filter((estimate) => selectedEstimates.includes(estimate.id));
      const isApproved = getEstimates.map((estimate) => estimate.isApproved).every((bool) => bool === true);
      if (isApproved) {
        const total = getEstimates.map((estimate) => estimate.estimateTotal).reduce((count, value) => count + value, 0);
        setEstimateTotal(total);
        setInvoiceTotal(total / 2);
        setIsEstimate(true);
        modalInvoice.current.openModal();
      } else {
        modalWarning.current.openModal(
          'Estimate(s) must be approved in order to create an invoice.',
          'Cannot Create Invoice'
        );
      }
    }
  }
  function calculateTotalEstimate(percentage: number) {
    const temporaryTotal = estimateTotal * (percentage / 100);
    setInvoiceTotal(temporaryTotal);
    setIsNegative(temporaryTotal < 0 ? true : false);
  }
  function removeSelectedEstimates(ids: number[]) {
    setSelectedEstimates(selectedEstimates.filter((id) => !ids.includes(id)));
  }
  function resetSelectedEstimates() {
    setSelectedEstimates([]);
  }

  const [selectedWorkOrders, setSelectedWorkOrders] = React.useState<number[]>([]);
  function handleSelectWorkOrder(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    const temporarySelected = event.target.checked
      ? [...selectedWorkOrders, id]
      : selectedWorkOrders.filter((s) => s !== id);
    setSelectedWorkOrders(temporarySelected);
  }

  function handleWorkOrderInvoiceSubmit() {
    if (selectedWorkOrders.length === 0) {
      modalWarning.current.openModal(
        'Please select at leaset one work order to create an invoice.',
        'Cannot Create Invoice'
      );
    } else {
      const getWorkOrders = data.workOrders.filter((workOrder) => selectedWorkOrders.includes(workOrder.id));
      const isFinished = getWorkOrders.map((workOrder) => workOrder.isFinished).every((bool) => bool === true);
      if (isFinished) {
        const total = getWorkOrders
          .map((workOrder) => workOrder.workOrderTotal)
          .reduce((count, value) => count + value, 0);
        setWorkOrderTotal(total);
        setInvoiceTotal(total);
        setIsEstimate(false);
        setIsNegative(false);
        setSelectedRetainers([]);
        modalInvoice.current.openModal();
      } else {
        modalWarning.current.openModal(
          'Work order(s) must be finished in order to create an invoice.',
          'Cannot Create Invoice'
        );
      }
    }
  }
  function removeSelectedWorkOrders(ids: number[]) {
    setSelectedWorkOrders(selectedWorkOrders.filter((id) => !ids.includes(id)));
  }
  function resetSelectedWorkOrders() {
    setSelectedWorkOrders([]);
    setSelectedRetainers([]);
  }

  const retainerInvoices = data.invoices.filter(
    (invoice) => invoice.estimateTotal > 0 && invoice.retainerParentId === null
  );
  const [selectedRetainers, setSelectedRetainers] = React.useState<number[]>([]);
  function onChangeRetainer(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    const temporarySelected = event.target.checked
      ? [...selectedRetainers, id]
      : selectedRetainers.filter((s) => s !== id);
    setSelectedRetainers(temporarySelected);
    const total = data.invoices
      .filter((invoice) => temporarySelected.includes(invoice.id))
      .map((invoice) => invoice.invoiceTotal)
      .reduce((accumulator, value) => accumulator + value, 0);
    const temporaryTotal = workOrderTotal - total;
    setInvoiceTotal(temporaryTotal);
    setIsNegative(temporaryTotal < 0 ? true : false);
  }

  function handleInvoiceDelete(id: number) {
    const getInvoice = data.invoices.find((invoice) => invoice.id === id);
    if (getInvoice.retainerParentId) {
      modalWarning.current.openModal(
        'Invoice retainer is being used. First delete Inv ' + getInvoice.retainerParentId + '.',
        'Cannot Delete Invoice'
      );
    } else {
      setDeleteId(id);
      modalDeleteInvoice.current.openModal();
    }
  }

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This job was deleted and can no longer be edited.
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            Job #{data.id}
            <Button type='button' size='sm' className='float-end' onClick={editJob} disabled={data.deleted}>
              <PencilSquare className='icon-align' /> Edit
            </Button>
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Company', content: data.company.name, link: '/companies/' + data.company.id },
              { title: 'Contact', content: data.contact.name, link: '/contacts/' + data.contact.id },
              { title: 'Project', content: data.project.name, link: '/projects/' + data.project.id },
              { title: 'Notes', content: data.notes }
            ]}
          />
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Estimates
            <DropdownButton
              title={
                <React.Fragment>
                  <PencilSquare className='icon-align' /> Edit
                </React.Fragment>
              }
              size='sm'
              className='float-end'
              disabled={data.deleted}
            >
              <Dropdown.Item onClick={() => modalAddEstimate.current.openModal()}>+ Add Estimate</Dropdown.Item>
              <Dropdown.Item onClick={handleEstimateInvoiceSubmit}>+ Create Invoice</Dropdown.Item>
            </DropdownButton>
          </Card.Title>
          <Table striped hover size='sm'>
            <thead>
              <tr>
                <th></th>
                <th>&#35;</th>
                <th>Date</th>
                <th>Company</th>
                <th>Project</th>
                <th>Contact</th>
                <th>Approved</th>
                <th>Invoice</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.estimates.length === 0 && (
                <tr>
                  <td colSpan={10}>None</td>
                </tr>
              )}
              {data.estimates.map((estimate) => (
                <tr key={estimate.id}>
                  <td>
                    <Form.Check
                      id={'estimateSelected' + estimate.id}
                      checked={selectedEstimates.includes(estimate.id)}
                      onChange={(event) => handleSelectEstimate(event, estimate.id)}
                      disabled={data.deleted || estimate.invoiceId ? true : false}
                    />
                  </td>
                  <ListLink to={'/estimates/' + estimate.id}>EST{estimate.id}</ListLink>
                  <td>{formatDate(estimate.date)}</td>
                  <ListLink to={'/companies/' + estimate.company.id}>{estimate.company.name}</ListLink>
                  <ListLink to={'/projects/' + estimate.project.id}>{estimate.project.name}</ListLink>
                  <ListLink to={'/contacts/' + estimate.contact.id}>{estimate.contact.name}</ListLink>
                  <td className='text-center'>{estimate.isApproved ? <Check2 aria-label='yes' /> : ''}</td>
                  <ListLink to={'/invoices/' + estimate.invoiceId}>
                    {estimate.invoiceId ? 'INV' + estimate.invoiceId : ''}
                  </ListLink>
                  <td>{formatMoney(estimate.estimateTotal)}</td>
                  <td className='text-center'>
                    <Button
                      variant='link'
                      className='btn-icon me-1'
                      onClick={() => modalDeleteEstimate.current.openModal(estimate.id)}
                      disabled={data.deleted || estimate.invoiceId ? true : false}
                    >
                      <Trash3 aria-label='delete' />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p className='text-end'>
            <b>Estimates Total:</b> {formatMoney(data.estimatesTotal)}
            {data.retainersTotal > 0 && (
              <React.Fragment>
                <br />
                <b>Retainer Total:</b> {formatMoney(data.retainersTotal)}
              </React.Fragment>
            )}
          </p>
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Work Orders
            <DropdownButton
              title={
                <React.Fragment>
                  <PencilSquare className='icon-align' /> Edit
                </React.Fragment>
              }
              size='sm'
              className='float-end'
              disabled={data.deleted}
            >
              <Dropdown.Item onClick={() => modalAddWorkOrder.current.openModal()}>+ Add Work Order</Dropdown.Item>
              <Dropdown.Item onClick={handleWorkOrderInvoiceSubmit}>+ Create Invoice</Dropdown.Item>
            </DropdownButton>
          </Card.Title>
          <Table striped hover size='sm'>
            <thead>
              <tr>
                <th></th>
                <th>&#35;</th>
                <th>Date</th>
                <th>Company</th>
                <th>Project</th>
                <th>Contact</th>
                <th>Finished</th>
                <th>Invoice</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.workOrders.length === 0 && (
                <tr>
                  <td colSpan={10}>None</td>
                </tr>
              )}
              {data.workOrders.map((workOrder) => (
                <tr key={workOrder.id}>
                  <td>
                    <Form.Check
                      id={'workOrderSelected' + workOrder.id}
                      checked={selectedWorkOrders.includes(workOrder.id)}
                      onChange={(event) => handleSelectWorkOrder(event, workOrder.id)}
                      disabled={data.deleted || workOrder.invoiceId ? true : false}
                    />
                  </td>
                  <ListLink to={'/workorders/' + workOrder.id}>WO{workOrder.id}</ListLink>
                  <td>{formatDate(workOrder.date)}</td>
                  <ListLink to={'/companies/' + workOrder.company.id}>{workOrder.company.name}</ListLink>
                  <ListLink to={'/projects/' + workOrder.project.id}>{workOrder.project.name}</ListLink>
                  <ListLink to={'/contacts/' + workOrder.contact.id}>{workOrder.contact.name}</ListLink>
                  <td className='text-center'>{workOrder.isFinished ? <Check2 aria-label='yes' /> : ''}</td>
                  <ListLink to={'/invoices/' + workOrder.invoiceId}>
                    {workOrder.invoiceId ? 'INV' + workOrder.invoiceId : ''}
                  </ListLink>
                  <td>{formatMoney(workOrder.workOrderTotal)}</td>
                  <td className='text-center'>
                    <Button
                      variant='link'
                      className='btn-icon me-1'
                      onClick={() => modalDeleteWorkOrder.current.openModal(workOrder.id)}
                      disabled={data.deleted || workOrder.invoiceId ? true : false}
                    >
                      <Trash3 aria-label='delete' />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p className='text-end'>
            <b>Work Orders Total:</b> {formatMoney(data.workOrdersTotal)}
          </p>
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>Invoices</Card.Title>
          <Table striped hover size='sm'>
            <thead>
              <tr>
                <th>&#35;</th>
                <th>Date</th>
                <th>Company</th>
                <th>Project</th>
                <th>Contact</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.invoices.length === 0 && (
                <tr>
                  <td colSpan={7}>None</td>
                </tr>
              )}
              {data.invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <ListLink to={'/invoices/' + invoice.id}>INV{invoice.id}</ListLink>
                  <td>{formatDate(invoice.date)}</td>
                  <td>{invoice.jobInfo.company.name}</td>
                  <td>{invoice.jobInfo.project.name}</td>
                  <td>{invoice.jobInfo.contact.name}</td>
                  <td>{formatMoney(invoice.invoiceTotal)}</td>
                  <td className='text-center'>
                    <Button
                      variant='link'
                      className='btn-icon'
                      onClick={() => handleInvoiceDelete(invoice.id)}
                      disabled={data.deleted}
                    >
                      <Trash3 aria-label='delete' />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p className='text-end'>
            <b>Invoices Total:</b> {formatMoney(data.invoicesTotal)}
          </p>
        </Card.Body>
      </Card>

      <FormButtons type='Job' redirectTo={redirectTo} showDelete={!data.deleted}>
        <React.Fragment>
          Are you sure you want to delete this job? Work Orders, Estimates, and Invoices related to this job will also
          be deleted!
        </React.Fragment>
      </FormButtons>

      <ModalForm
        title='Add Work Orders'
        url={`${baseURL}/workorder`}
        ref={modalAddWorkOrder}
        size='xl'
        putId={data.id}
        draggable
      >
        <SelectorTable name='workOrder' fetchURL='workorders' />
      </ModalForm>

      <ModalDelete
        title='Remove Work Order'
        url={`${baseURL}/workorder`}
        ref={modalDeleteWorkOrder}
        deleteId={data.id}
        callback={(id) => removeSelectedWorkOrders([id])}
      >
        Are you sure you want to remove this work order from the job?
      </ModalDelete>

      <ModalForm
        title='Add Estimates'
        url={`${baseURL}/estimate`}
        ref={modalAddEstimate}
        size='xl'
        putId={data.id}
        draggable
      >
        <SelectorTable name='estimate' fetchURL='estimates' />
      </ModalForm>

      <ModalDelete
        title='Remove Estimate'
        url={`${baseURL}/estimate`}
        ref={modalDeleteEstimate}
        deleteId={data.id}
        callback={(id) => removeSelectedEstimates([id])}
      >
        Are you sure you want to remove this estimate from the job?
      </ModalDelete>

      <ModalForm title='Edit Job' url={baseURL} ref={modalEdit} putId={data.id} disabled={data.deleted} draggable>
        <Form.Control type='hidden' name='redirectTo' value={redirectTo} />
        <SelectorAPIGroup
          value={{ companyId: data.company.id, contactId: data.contact.id, projectId: data.project.id }}
          valueSelect={{
            companyId: elementSelect?.companyId,
            contactId: elementSelect?.contactId,
            projectId: elementSelect?.projectId
          }}
          addCompany={() => groupHelper.current.addCompany()}
          addContact={() => groupHelper.current.addContact()}
          linkContact={() => groupHelper.current.linkContact()}
          addProject={() => groupHelper.current.addProject()}
        />
        <InputTextArea name='notes' size='col-12' value={data?.notes || ''} valueSelect={elementSelect?.notes} />
      </ModalForm>

      <SelectorAPIHelper
        elementSelect={elementSelect}
        setElementSelect={setElementSelect}
        saveForm={saveForm}
        modal={modalEdit}
        ref={groupHelper}
      />

      <ModalForm
        title='Create Invoice'
        url='/invoices'
        ref={modalInvoice}
        disabled={isNegative}
        noDisableForm
        noChanges
        reset={isEstimate ? resetSelectedEstimates : resetSelectedWorkOrders}
        draggable
      >
        {isNegative && (
          <Col>
            <Alert variant='danger' className='mt-3'>
              Invoice value cannot be negative.
            </Alert>
          </Col>
        )}
        <Form.Control type='hidden' name='isEstimate' value={isEstimate?.toString()} />
        <Form.Control type='hidden' name='jobId' value={data.id} />
        {isEstimate ? (
          <Form.Control type='hidden' name='estimateIds' value={JSON.stringify(selectedEstimates)} />
        ) : (
          <Form.Control type='hidden' name='workOrderIds' value={JSON.stringify(selectedWorkOrders)} />
        )}
        {selectedRetainers && (
          <Form.Control type='hidden' name='retainerIds' value={JSON.stringify(selectedRetainers)} />
        )}
        <Input name='poNumber' displayName='P.O. #' />
        <Input name='jobNumber' />
        <InputTextArea name='message' />
        {isEstimate && (
          <InputNumber
            name='percentage'
            value='50'
            percentage
            setValue={(percentage) => calculateTotalEstimate(percentage)}
          />
        )}
        {!isEstimate && retainerInvoices.length > 0 && (
          <React.Fragment>
            <p className='mt-3'>Apply Retainers</p>
            {retainerInvoices.map((invoice, index) => (
              <Col key={index} xs='12'>
                <Form.Check
                  type='switch'
                  id={'invoice-' + invoice.id}
                  name={'invoice-' + invoice.id}
                  label={'Inv ' + invoice.id + ': ' + formatMoney(invoice.invoiceTotal)}
                  checked={selectedRetainers.includes(invoice.id)}
                  onChange={(event) => onChangeRetainer(event, invoice.id)}
                  disabled={data.deleted}
                />
              </Col>
            ))}
          </React.Fragment>
        )}
        <p className={'mt-3 text-end' + (isNegative ? ' text-danger' : '')}>
          Invoice Total: {formatMoney(invoiceTotal || 0)}
        </p>
      </ModalForm>

      <ModalWarning title='Error' ref={modalWarning} />

      <ModalDelete title='Delete Invoice' url='/invoices' deleteId={deleteId} ref={modalDeleteInvoice}>
        Are you sure you want to delete this invoice?
      </ModalDelete>
    </React.Fragment>
  );
}
