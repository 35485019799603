import { format, parseISO } from 'date-fns';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

import { Error } from '@/helper/interfaces';
import { allCountries, allStatuses } from '@/helper/lists';

export function camelToTitle(input: string) {
  // eslint-disable-next-line unicorn/prefer-string-replace-all
  const result = input.replace(/([A-Z]+)/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function formatStatus(value: string, isFinished = false) {
  if (isFinished) {
    value = 'FINISHED';
  }
  return allStatuses.find((s) => s.key === value).value;
}

export function getColorClass(status: string) {
  let color = 'default';
  if (status == 'Firm') {
    color = 'yellow';
  } else if (status == 'Finished') {
    color = 'green';
  }
  return color;
}

export function formatCountry(value: string) {
  return allCountries.find((s) => s.key === value).value;
}

export function formatDate(date: string) {
  try {
    return format(parseISO(date), 'MMMM d, yyyy');
  } catch {
    return '';
  }
}

export function formatTime(date: string) {
  try {
    return format(parseISO(date), 'h:mm a');
  } catch {
    return '';
  }
}

export function formatMoney(value: number | null) {
  return typeof value === 'number' ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00';
}

export function formatPhone(value: string) {
  return value.startsWith('+1') ? formatPhoneNumber(value) : formatPhoneNumberIntl(value);
}

export function formatAddress(address: string, city: string, state: string, zip: string, country: string) {
  return (
    (address ? address + '\n' : '') +
    (city ? city + ', ' : '') +
    (state ? state + ' ' : '') +
    (zip ?? '') +
    (country ? '\n' + formatCountry(country) : '')
  );
}

export function formatErrors(errorResponse: Error[]) {
  let errors = {} as any;
  if (errorResponse) {
    for (const error of errorResponse) {
      errors = {
        ...errors,
        [error.path]: error.msg
      };
    }
  }
  return errors;
}

export function removeKeysWithPrefix<T extends Record<string, any>>(object: T, prefix: string): T {
  const newObject: Partial<T> = {};
  for (const key in object) {
    if (key.startsWith(prefix)) {
      continue; // Skip keys with the specified prefix
    }
    // eslint-disable-next-line security/detect-object-injection
    newObject[key] = object[key];
  }
  return newObject as T;
}

// for testing loading states add .then(sleeper(5000)) to api promise chain
export function sleeper(ms: number) {
  return function (x: any) {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms));
  };
}

export function createPDF(data: Blob, id: number, name: string) {
  const blob = new Blob([data], { type: 'application/pdf' });
  downloadFile(blob, name + '.pdf');
}

export function createCSV(data: string, name: string) {
  const blob = new Blob([data], { type: 'text/csv' });
  downloadFile(blob, name + '.csv');
}

function downloadFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.append(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}
