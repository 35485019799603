import * as React from 'react';
import { Alert, Container, Modal, Nav, Row, Spinner } from 'react-bootstrap';
import { Outlet, useFetcher, useMatches } from 'react-router-dom';

import { ModalWarning } from '@/components/modal-warning';
import { PageHeader } from '@/components/pageheader';
import { SearchBar } from '@/components/search-bar';
import { Match } from '@/helper/interfaces';
import { useStore } from '@/store';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  const fetcher = useFetcher();
  const [isLoading, setIsLoading] = React.useState(false);
  const modal = React.useRef(null);
  const [message, setMessage] = React.useState('');
  React.useEffect(() => {
    if (fetcher.state == 'loading' || fetcher.state == 'submitting') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (fetcher.data?.status == 400) {
        setMessage(fetcher.data?.message);
        modal.current.openModal();
      }
    }
  }, [fetcher]);

  const { selectedInvoices, setSelectedInvoices } = useStore();
  async function exportSelected() {
    fetcher.submit(
      { invoiceIds: JSON.stringify(selectedInvoices) },
      {
        method: 'patch',
        action: '/invoices/export'
      }
    );
  }
  function clearSelected() {
    setSelectedInvoices([]);
  }

  const searchFilters = [{ name: 'Non-Exported', urlParam: 'noExport' }];

  return (
    <React.Fragment>
      <Container fluid={isList ? true : false}>
        {user?.role?.invoices ? (
          <React.Fragment>
            {isList ? (
              <React.Fragment>
                <PageHeader crumbs={crumbs}>Invoices</PageHeader>
                <Nav>
                  {selectedInvoices.length > 0 && (
                    <React.Fragment>
                      <Nav.Link onClick={exportSelected}>Export Selected</Nav.Link>
                      <Nav.Link onClick={clearSelected}>Clear Selected</Nav.Link>
                    </React.Fragment>
                  )}
                  <SearchBar filters={searchFilters} />
                </Nav>
              </React.Fragment>
            ) : (
              <PageHeader crumbs={crumbs}>Invoice</PageHeader>
            )}
            <Outlet />
          </React.Fragment>
        ) : (
          <Row className='justify-content-center'>
            <Alert variant='warning' className='col-6 mt-5'>
              You are not authorized to access this page
            </Alert>
          </Row>
        )}
      </Container>
      <ModalWarning title='Error' ref={modal}>
        {message}
      </ModalWarning>
      <Modal show={isLoading} backdrop='static' keyboard={false}>
        <Modal.Body className='text-center'>
          The CSV file is exporting...
          <br />
          <br />
          <Spinner />
          <br />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
