import * as React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { FormContext } from '@/helper/context';

import { CustomInput } from './datepicker-custom';

interface Properties {
  value?: string;
  valueSelect?: string;
  trackChanges?: boolean;
}

export function SelectorDate({ value, valueSelect, trackChanges = true }: Properties) {
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const getDate = valueSelect ?? value;
  const [dateState, setDateState] = React.useState(
    getDate ? new Date(getDate) : new Date(new Date().setHours(10, 0, 0, 0))
  );

  React.useEffect(() => {
    const didChange = valueSelect ? (valueSelect == value ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      date: didChange
    };
    setWasChanged(trackChanges ? Object.values(changeTracker.current).includes(true) : true);
  }, []);

  function setStart(selectedDate: Date) {
    setDateState(selectedDate);

    const didChange = selectedDate?.toISOString() == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      date: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <React.Fragment>
      <Form.Control type='hidden' name='date' value={dateState?.toISOString()} />
      <Form.Group className='mt-3 col-6'>
        <span className={'d-block form-label' + (errors?.date ? ' is-invalid' : '')}>Date</span>
        <DatePicker
          selected={dateState}
          disabled={disabled}
          onChange={(date) => setStart(date)}
          dateFormat='MMM do, yyyy'
          customInput={<CustomInput />}
          todayButton='Today'
        />
        <Form.Control.Feedback type='invalid'>{errors?.date}</Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
}
