/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Alert, Form, Spinner, Table } from 'react-bootstrap';

import { getAll } from '@/helper/apicalls';
import { FormContext } from '@/helper/context';
import { formatDate } from '@/helper/utility';

interface Properties {
  name: string;
  fetchURL: string;
}

export function SelectorTable({ name, fetchURL }: Properties) {
  const nameIds = name + 'Ids';

  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  async function getData(URL: string) {
    setData(await getAll(URL));
  }

  React.useEffect(() => {
    getData(fetchURL + '?noJob').then(() => {
      setIsLoading(false);
    });
  }, []);

  const [search, setSearch] = React.useState('');
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
    setIsLoading(true);
    getData(fetchURL + '?noJob&search=' + event.target.value).then(() => {
      setIsLoading(false);
    });
  }

  function handleSelect(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    const temporarySelected = event.target.checked ? [...selected, id] : selected.filter((s) => s !== id);
    setSelected(temporarySelected);

    const didChange = temporarySelected.length === 0 ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <React.Fragment>
      <div className='col-4 ms-auto'>
        {errors[nameIds] && <Alert variant='danger'>{errors[nameIds]}</Alert>}
        <Form.Label className='visually-hidden' htmlFor='search'>
          Search
        </Form.Label>
        <Form.Group className='mb-2'>
          <Form.Control
            id='search'
            type='search'
            placeholder='Search'
            value={search}
            onChange={handleChange}
            disabled={disabled}
          />
        </Form.Group>
      </div>

      <Form.Control type='hidden' name={nameIds} value={JSON.stringify(selected)} />
      <div className='position-relative'>
        <div id='loader' className={isLoading ? 'loading' : ''}>
          <Spinner animation='border' />
        </div>
        <div id='fader' className={isLoading || disabled ? 'loading' : ''}>
          <div className='table-scroll'>
            <Table striped hover size='sm'>
              <thead>
                <tr>
                  <th></th>
                  <th>&#35;</th>
                  <th>Date</th>
                  <th>Company</th>
                  <th>Project</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  const company = d.company ?? d.session?.company;
                  const contact = d.contact ?? d.session?.contact;
                  const project = d.project ?? d.session?.project;
                  const date = d.date ?? d.session?.startTime;
                  return (
                    <tr key={d.id}>
                      <td>
                        <Form.Check
                          id={name + d.id}
                          onChange={(event) => handleSelect(event, d.id)}
                          disabled={disabled}
                        />
                      </td>
                      <td>{d.id}</td>
                      <td>{formatDate(date)}</td>
                      <td>{company.name}</td>
                      <td>{project.name}</td>
                      <td>{contact.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
