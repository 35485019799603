import * as React from 'react';

const StoreContext = React.createContext(undefined);

export const StoreProvider = ({ children }: { children: React.ReactElement }) => {
  const [user, setUser] = React.useState<object>({});
  const [response, setResponse] = React.useState<object>();
  const [selectedInvoices, setSelectedInvoices] = React.useState<number[]>([]);

  const store = {
    user,
    setUser,
    response,
    setResponse,
    selectedInvoices,
    setSelectedInvoices
  };

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = () => {
  return React.useContext(StoreContext);
};
