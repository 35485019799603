import { format } from 'date-fns';

import { downloadPDF } from '@/helper/apicalls';

import { baseURL } from './const';

export async function printLoader({ params }: any) {
  // eslint-disable-next-line unicorn/prefer-ternary
  if (params.id) {
    return downloadPDF(
      baseURL,
      params.id,
      'LIME_MixerReport_' + params.id + '_' + format(new Date(), 'yyyy_MM_dd_HH.mm.ss')
    );
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
