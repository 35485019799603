import * as React from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

interface Properties {
  filters?: filterObject[];
}
interface filterObject {
  name: string;
  urlParam: string;
}

export function SearchBar({ filters }: Properties) {
  const [parameters, setParameters] = useSearchParams();
  const search = parameters.get('s') || '';

  // prevent enter key from reloading page
  function handleSearch(event: React.FormEvent) {
    event.preventDefault();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.target.value;
    let shouldReplace = false;

    // only add to location history on first letter
    if (input.length > 1) shouldReplace = true;
    parameters.set('s', input);
    parameters.delete('p');
    setParameters(parameters, { replace: shouldReplace });
  }

  function setFilter(p: string) {
    parameters.set('f', p);
    setParameters(parameters);
  }
  const urlFilterParameter = parameters.get('f');
  const urlFilterName = filters?.find((filter) => filter.urlParam === urlFilterParameter)?.name;

  return (
    <React.Fragment>
      {filters && (
        <DropdownButton
          id='filter'
          title={'Filter' + (urlFilterName ? ': ' + urlFilterName : '')}
          variant='outline-secondary'
          className='ms-auto'
        >
          {urlFilterName && <Dropdown.Item onClick={() => setFilter('')}>Clear Filter</Dropdown.Item>}
          {filters?.map((filter) => (
            <Dropdown.Item
              key={filter.urlParam}
              className={urlFilterParameter == filter.urlParam ? 'active' : ''}
              onClick={() => setFilter(filter.urlParam)}
            >
              {filter.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
      <Form noValidate className={filters ? 'ms-2' : 'ms-auto'} onSubmit={handleSearch}>
        <div className='col-12'>
          <Form.Label className='visually-hidden' htmlFor='search'>
            Search
          </Form.Label>
          <Form.Group className='mb-2'>
            <Form.Control id='search' type='search' placeholder='Search' value={search} onChange={handleChange} />
          </Form.Group>
        </div>
      </Form>
    </React.Fragment>
  );
}
